import { Namespace, TOptions, i18n as i18nInterface } from 'i18next';
import { useTranslation as i18nextUseTranslation } from 'react-i18next';

export let i18nInstance: i18nInterface;

export type { TOptions };

/**
 * i18next library creates new instance on import.
 * Call this function from the package where you initialize i18next
 * so all code utilizing this file, will use the same i18n instance.
 */
export const bindI18n = (i18n: i18nInterface) => {
  i18nInstance = i18n;
};

/**
 * React hook for utilizing i18n.
 */
export const useTranslation = (ns?: Namespace) => {
  return i18nextUseTranslation(ns, {
    i18n: i18nInstance,
  });
};

/**
 * Translate plural string
 *
 * @param singular
 * @param plural
 * @param options Array of following options:
 *  - count - number for pluralization
 *  - ...
 * @param context context string which is only needed for 'gettext-extractor' package to parse the context correctly
 */
export const translatePlural = function (singular: string, plural: string, options: TOptions = {}, context = ''): string {
  const { language, t, services } = i18nInstance;
  options.defaultValue = singular;

  let res = t(singular, options);

  if (options.count === 1) { // early exit for singular
    return res;
  }

  if (language.startsWith('en')) {
    res = services.interpolator.interpolate(
      plural,
      { ...options },
      language,
      options.interpolation || {}
    );
  }

  return res;
};
