export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
}

/**
 * Apple is awesome! There's no way to reliably detect iPad OS 🎉
 * So we use this hack to do a best-effort detection instead.
 *
 * https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
 */
function isIPadOS() {
  return window.AuthenticatorAssertionResponse === undefined
        && window.AuthenticatorAttestationResponse === undefined
        && window.AuthenticatorResponse === undefined
        && window.Credential === undefined
        && window.CredentialsContainer === undefined
        && window.DeviceMotionEvent !== undefined
        && window.DeviceOrientationEvent !== undefined
        && navigator.maxTouchPoints === 5
        && navigator.plugins.length === 0
        && navigator.platform !== 'iPhone';
}

export function isIOS() {
  // @ts-ignore Capacitor is only available in app client
  return window.Capacitor?.platform === 'ios' || isIPadOS() || (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  );
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export function supportsLandscape() {
  const minScreenSize = 530;
  return window.screen.width >= minScreenSize && window.screen.height >= minScreenSize;
}

/**
 * Returns if device is in landscape mode
 * @param minWidth defaults to 768px
 * @param checkAspectRatio defaults to true
 */
export const isLandscapeMode = (minWidth = 768, checkAspectRatio = true) => {

  if (checkAspectRatio) {
    return window.innerWidth > window.innerHeight && window.innerWidth >= minWidth;
  }

  return window.innerWidth >= minWidth;
};
