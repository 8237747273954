import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import dayjs from 'dayjs';
import localizedTime from 'dayjs/plugin/localizedFormat';
import { initReactI18next } from 'react-i18next';

import { bindI18n } from '~common/i18n';

dayjs.extend(localizedTime);

/**
 * Find all available translation files for play-ui and the app client,
 * they will be transformed to 18next json by our vite-plugin-po-to-json
 */
const playUiTranslations = import.meta.glob('~play-ui/translations/*.po');
const appTranslations = import.meta.glob('../translations/*.po');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: '{{ns}}/{{lng}}.po',
      async request(_opts, url, _payload, callback) {
        /**
         * When we load a po file, it will be transformed by our vite-plugin-po-to-json
         * to a i18next json file on the fly. With this i18next backend,
         * instead of requesting the po file from a specific path, we return the transformed "imported" json file.
         */
        const [ns, lng] = url.split('/');

        const translations = ns === 'play-ui' ? playUiTranslations : appTranslations;
        const file = Object.keys(translations).find((t) => t.endsWith(lng!));
        const data = await translations[file!]() as { default: Record<string, string> };

        callback(null, { status: 200, data: JSON.stringify(data.default) });
      },
    },
    supportedLngs: ['en', 'de', 'fr-CH', 'it-CH', 'cz', 'sk', 'ru', 'pl', 'hu', 'ro', 'nl', 'si', 'ar'],
    fallbackLng: {
      fr: ['fr-CH'],
      it: ['it-CH'],
      default: ['en'],
    },
    keySeparator: false,
    nsSeparator: false,

    ns: ['app', 'play-ui'],
    defaultNS: 'app',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    compatibilityJSON: 'v3',
  }, (err) => {
    dayjs.locale(i18n.language);

    if (err) {
      console.error('error while initializing i18next: ' + JSON.stringify(err));
    }
  });

i18n.on('languageChanged', lng => {
  console.info(`Changed language to "${lng}"`);
  dayjs.locale(lng);
});

bindI18n(i18n);

export default i18n;
